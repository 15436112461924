/* src/styles/App.module.css */

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.nav {
  background-color: #333;
  color: white;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 80px; */
  overflow-y: auto; /* Body 영역에서만 스크롤 발생 */
}
