/* src/styles/StepDetail.module.css */

.stepDetail {
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    margin: 40px auto;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: calc(100% - 60px); /* nav 바 아래에서만 스크롤되도록 설정 */
    overflow-y: auto; /* 이 부분만 스크롤 가능 */
  }
  
  .backButton {
    padding: 10px 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px; /* 표와 버튼 간격 */
    align-self: flex-start; /* 왼쪽 정렬 */
  }
  
  .backButton:hover {
    background-color: #5a6268;
  }
  
  .problemTable {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    overflow-y: auto; /* 표 내부에서만 스크롤 */
    max-height: 60vh; /* 화면의 60%까지 테이블 크기 */
  }
  
  .problemTable th, .problemTable td {
    padding: 14px 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  
  .problemTable th {
    background-color: #f1f3f5;
    font-weight: bold;
    color: #333;
  }
  
  .problemTable td {
    color: #555;
  }
  
  .problemTable tr:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .problemTable tr:last-child td {
    border-bottom: none;
  }
  