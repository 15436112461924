.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .modalContent h3 {
    margin-top: 0;
  }
  
  .modalContent label {
    display: block;
    margin-top: 10px;
    text-align: left;
  }
  
  .modalContent textarea {
    width: 100%;
    height: 60px;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modalContent button {
    margin-top: 15px;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modalContent button:hover {
    background-color: #45a049;
  }
  