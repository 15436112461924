/* src/styles/Footer.module.css */

.footer {
    background-color: #282c34; /* 푸터 배경색 */
    color: #ffffff; /* 텍스트 색상 */
    padding: 20px;
    text-align: center;
    font-size: 0.9em;
    border-top: 1px solid #444;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .footer p {
    margin: 0;
  }
  
  .link {
    color: #61dafb; /* 링크 색상 */
    text-decoration: none;
    font-weight: bold;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  