/* src/styles/Navigator.module.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.leftSection {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 8px;
}

.navButton {
  color: white;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navButton:hover {
  background-color: #444;
}

.rightSection {
  display: flex;
  align-items: center;
}

.problemSearch {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.problemInput {
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.searchButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #45a049;
}

.githubButton {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.githubButton:hover {
  background-color: #666;
}

.githubIcon {
  margin-right: 10px;
}

.welcomeMessage {
  font-size: 1em;
  color: #61dafb;
  font-weight: bold;
  margin-right: 20px;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid white;
}

.moreButtonContainer {
  position: relative;
}

.moreButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5em;
  transition: transform 0.2s ease;
}

.moreButton:hover {
  transform: rotate(90deg);
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 40px;
  background-color: white;
  color: black;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.logoutButton {
  background: none;
  border: none;
  padding: 10px;
  color: black;
  text-align: left;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
}

.logoutButton:hover {
  background-color: #f0f0f0;
}
