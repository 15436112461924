.problemContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 20px;
  padding: 20px;
  height: calc(100% - 90px);
}

.problemDescription {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}

.inputOutputBox {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
}

.inputOutputBox pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.editorContainer {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.languageSelector {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end; /* 우측 정렬 */
  gap: 10px; /* 버튼과 언어 선택 사이 간격 */
  align-items: center;
}

.copyButton {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copyButton:hover {
  background-color: #45a049;
}

.languageSelector select {
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.editorContainer .output {
  height: 30%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  overflow-y: auto;
}

.buttonContainer {
  grid-column: 1 / 3;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.leftButtons, .rightButtons {
  display: flex;
  gap: 10px;
}

.leftButtons button, .rightButtons button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.leftButtons button:hover, .rightButtons button:hover {
  background-color: #45a049;
}

.leftButtons button:active, .rightButtons button:active {
  background-color: #3e8e41;
}

.testResultBox {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.testResultBox h4 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.testResultBox p {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
  font-size: 14px;
}

.resultLabel {
  flex: 0 0 60px;
  font-weight: bold;
  white-space: nowrap;
  align-self: flex-start;
}

.resultValue {
  flex: 1;
  font-size: 14px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  align-self: flex-start;
}

.testResultBox .pass {
  color: blue;
  font-weight: bold;
}

.testResultBox .fail {
  color: red;
  font-weight: bold;
}

/* 모달 오버레이 */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingText {
  color: white;
  font-size: 1.2em;
  margin-top: 15px;
}
